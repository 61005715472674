<template>
  <div class="table-settings" v-click-outside="closeAction">
    <button
      class="table-settings__btn btn sm transparent-primary"
      :class="{ active: isOpen }"
      type="button"
      :data-btn="uniqId"
      @click="toggleAction"
    >
      <BaseIcon class="ic-16 primary" icon="settings" />
      Настройки колонок
    </button>

    <div v-if="isOpen" class="table-settings__ddown box" :data-body="uniqId">
      <!-- Head -->
      <div class="table-settings__head flex ai-c jc-sb ggap-10 p-10">
        <h3 class="title">Настройки колонок</h3>
        <button class="btn sm transparent-grey" @click="toggleAll">
          <BaseIcon class="ic-16" :class="isToggleAll ? 'primary' : 'red'" :icon="isToggleAll ? 'eye' : 'eye-off'" />
          {{ isToggleAll ? 'Скрыть все' : 'Показать все' }}
        </button>
      </div>

      <!-- Body -->
      <div class="table-settings__body overflow-a">
        <Draggable v-model="cols" item-key="key" ghost-class="ghost" handle=".handle">
          <template #item="{ element }">
            <Item :col="element" />
          </template>
        </Draggable>
      </div>

      <!-- Foot -->
      <div class="table-settings__foot flex ai-c jc-sb ggap-10 p-10">
        <button class="btn sm cancel" @click="resetAction" :disabled="isNotDefault">Сбросить</button>
        <button class="btn sm primary" @click="saveAction" :disabled="isChanged">Применить</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import Draggable from 'vuedraggable'
import { cloneDeep, isEqual } from 'lodash'
import { useStore } from 'vuex'
import { defineProps, computed, nextTick, ref, toRefs } from 'vue'
import { BaseIcon } from '@/components'
import Item from './Item.vue'
import { calcCoords } from '@/plugins'
import ordersCols from '@/assets/json/orders-cols'

// Props
const props = defineProps(['storeName'])

// Data
const { storeName } = toRefs(props)
const store = useStore()
const uniqId = 'table-settings'
const cols = ref([])
const isOpen = ref(false)
const isToggleAll = ref(true)

// Computed
const colsStore = computed(() => store.getters[`${storeName.value}/tableCols`])
const isNotDefault = computed(() => {
  // let res = true
  const oldCols = cloneDeep(ordersCols)
  const newCols = cloneDeep(colsStore.value)
  return isEqual(oldCols.sort(), newCols.sort())
})
const isChanged = computed(() => {
  const oldCols = cloneDeep(colsStore.value)
  const newCols = cloneDeep(cols.value)
  return isEqual(oldCols.sort(), newCols.sort())
})

// Created
cols.value = cloneDeep(colsStore.value)

// Methods
async function toggleAction() {
  isOpen.value = !isOpen.value
  calcCoords(uniqId, 'rtl')
}
function closeAction() {
  isOpen.value = false
  cols.value = cloneDeep(colsStore.value)
}
function resetAction() {
  store.commit(`${storeName.value}/RESET_COLS`)
  nextTick(() => (cols.value = cloneDeep(colsStore.value)))
  closeAction()
}
function saveAction() {
  const payload = cloneDeep(cols.value)
  store.commit(`${storeName.value}/SET_COLS`, payload)
  closeAction()
}
function toggleAll() {
  isToggleAll.value = !isToggleAll.value
  cols.value.map((el) => (el.show = isToggleAll.value))
}
</script>

<style lang="scss" scoped>
.table-settings {
  &__ddown {
    position: fixed;
    z-index: 2001;
    box-shadow: var(--box-shadow);
    width: 350px;
  }

  &__head {
    border-bottom: var(--b1);
  }

  &__body {
    max-height: calc(100vh - 350px);
  }

  &__foot {
    border-top: var(--b1);
  }
}
</style>
