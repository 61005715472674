<template>
  <RouterView />

  <BaseToolbar>
    <div class="flex jc-sb h-100">
      <!-- Settings -->
      <div class="flex ai-c ggap-10">
        <FormSelect
          label=""
          placeholder="Не группировать"
          class="sm"
          :options="optionsGroup"
          required
          v-model="tableGrouped"
        />

        <TableSettings storeName="orders" />
      </div>

      <!-- Filter status -->
      <div class="flex ai-c ggap-10">
        <template v-if="countFilters">
          <span class="divider" />
          <button class="btn sm transparent-primary pl-10 pr-10" @click="clearFilters">
            <BaseIcon class="ic-16 red" icon="filter" />
            Сбросить фильтры
            <small class="marker primary">
              {{ countFilters }}
            </small>
          </button>
        </template>
      </div>
    </div>
  </BaseToolbar>

  <div class="content pos-r z1 overflow-a default-mains">
    <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg" />
    <TableWrapper class="max-content" :cols="cols" :rows="rows" :isFoot="false" />

    <TableNative
      :cols="cols"
      :rows="rows"
      :isOpenAll="isOpenAll"
      :visited="visitedId"
      :groupedRows="groupedRows"
      :isGrouped="!!tableGrouped"
      @toggleAll="toggleAll"
    >
      <!-- thead -->
      <template #th="{ col }">
        <div class="flex ai-c ggap-5">
          <!-- Filter -->
          <Filters v-if="col.filter" :col="col" @getData="getRows" />

          <!-- Sort -->
          <button
            v-if="col.sort"
            class="btn transparent-grey xsm pl-5 pr-5"
            :class="{
              active: tableSort.key === col.key,
              rotate: tableSort.key === col.key && tableSort.direction === 'asc'
            }"
            type="button"
            v-tippy="'Сортировка'"
            @click="setSort(col.key)"
          >
            <BaseIcon class="ic-14 grey" icon="sort" />
          </button>
        </div>
      </template>

      <!-- tbody -->
      <template #td="{ col, row }">
        <Cell :col="col" :row="row" />
      </template>
    </TableNative>
    <BaseLoad class="pos-s" v-if="isLoadMore" />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, watchEffect, onMounted } from 'vue'
import { BaseIcon, BaseLoad, BaseToolbar, TableNative, FormSelect } from '@/components'
import TableSettings from './components/table-settings/Index.vue'
import Cell from './components/table/Cell.vue'
import orders from '../../api/modules/orders'
import Filters from './components/table/Filters.vue'
import socketConnect from '@/plugins/socket-connect'

// Data
const store = useStore()
const requestCount = ref(0)
const isLoad = ref(true)
const isLoadMore = ref(false)
const isOpenAll = ref(true)
const filterStatus = ref('')
const groupedRows = ref([])

const optionsGroup = [
  {
    text: 'Группировать по исполнителю',
    value: 'responsible'
  },
  {
    text: 'Группировать по типу заявки',
    value: 'type'
  },
  {
    text: 'Группировать по инициатору',
    value: 'owner'
  },
  {
    text: 'Группировать по проекту',
    value: 'company'
  },
  {
    text: 'Группировать по статусу',
    value: 'status'
  }
]

const profile = computed(() => store.getters.profile)
const page = computed(() => store.getters['orders/getPage'])
const pageCount = computed(() => store.getters['orders/getPageCount'])
const countFilters = computed(() => Object.keys(store.getters['orders/filters']).length)
const tableSort = computed(() => store.getters['orders/tableSort'])
const visitedId = computed(() => store.getters['orders/item']?.id || 0)
const colsStore = computed(() => store.getters['orders/tableCols'])
const tableGrouped = computed({
  get: () => store.getters['orders/tableGrouped'],
  set: (val) => store.commit('orders/SET_TABLE_GROUPED_BY', val)
})
const cols = computed(() => {
  // Убираем колонку по которой группируют
  return colsStore.value.map((item) => {
    if (tableGrouped.value && item.key === tableGrouped.value) {
      item.disabled = true
    } else {
      item.disabled = false
    }
    return item
  })
})
const rows = computed(() => {
  const res = store.getters['orders/rows']?.length ? store.getters['orders/rows'] : []
  return filterStatus.value ? res.filter((item) => item.status.uid === filterStatus.value) : res
})

// Created
getRows()
if (localStorage.getItem('choco_grouped')) {
  store.commit('orders/SET_TABLE_GROUPED_BY', localStorage.getItem('choco_grouped'))
}
profile?.value?.id && wsConnect()

// Mounted
onMounted(() => {
  const el = document.querySelector('.default-mains')
  el.addEventListener('scroll', async function () {
    if (el.scrollTop > el.scrollHeight - 1000) await getNextRows()
  })
})

// Watch
watchEffect(() => {
  if (tableGrouped.value && rows.value?.length) groupedBy()
})

// Methods
function getNextRows() {
  if (page.value < pageCount.value && !isLoadMore.value) {
    isLoadMore.value = true
    store.dispatch('orders/GET_MORE_ROWS').finally(() => {
      isLoadMore.value = false
    })
  }
}

function clearFilters() {
  store.commit('orders/CLEAR_FILTERS')
  getRows()
}

function setSort(key) {
  store.commit('orders/SET_SORT', key)
  getRows()
}

function getRows() {
  isLoad.value = true
  store.dispatch('orders/GET_ROWS').finally(() => {
    requestCount.value = requestCount.value + 1
    isLoad.value = false
  })
}

function getUniqArray(items) {
  const uniqArray = []
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const index = uniqArray.findIndex((el) => el?.id === item[tableGrouped.value]?.id)
    if (index === -1) uniqArray.push(item[tableGrouped.value])
  }
  return uniqArray
}

function groupedBy() {
  isOpenAll.value = true
  groupedRows.value.splice(0)

  // Собираем все уникальные ключи
  const uniqItems = rows.value?.length ? getUniqArray(rows.value) : []

  // Формируем массив
  for (let uniqItem of uniqItems) {
    if (uniqItem?.id) {
      groupedRows.value.push({
        id: uniqItem?.id || 0,
        groupedItem: uniqItem,
        groupedKey: tableGrouped.value,
        groupedValue: uniqItem?.name || uniqItem?.fullname || uniqItem?.project || '',
        opened: true,
        subrows: rows.value.filter((row) => row[tableGrouped.value]?.id === uniqItem?.id)
      })
    } else {
      groupedRows.value.unshift({
        id: 0,
        groupedItem: uniqItem,
        groupedKey: tableGrouped.value,
        groupedValue: '',
        opened: true,
        subrows: rows.value.filter((row) => row[tableGrouped.value]?.id === uniqItem?.id)
      })
    }
  }
}

function toggleAll() {
  isOpenAll.value = !isOpenAll.value
  groupedRows.value.forEach((item) => (item.opened = isOpenAll.value))
}

function addOrUpdateItem(id, flag) {
  orders.get(id).then((res) => {
    const action = flag === 'update' ? 'orders/UPDATE_ROW' : 'orders/ADD_ROW'
    store.commit(action, res.data.data)
  })
}

// Methods:websocket
function wsConnect() {
  const userId = profile?.value?.id
  const name = `sysadmin.request.${userId}`
  socketConnect.channel(name).listen('.NewSysadminRequestBroadcast', (data) => {
    data?.application_id && addOrUpdateItem(data.application_id, 'add')
  })

  socketConnect.channel('sysadmin.request.status').listen('.SysadminRequestListStatusChangedBroadcast', (data) => {
    data?.application_id && addOrUpdateItem(data.application_id, 'update')
  })
}
</script>
