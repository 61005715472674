<template>
  <!-- Status -->
  <template v-if="col.key === 'status'">
    <div class="igrid cut">
      <small
        v-if="row[col.key]"
        class="marker cut"
        :style="{ color: row[col.key].text_color, backgroundColor: row[col.key].background_color }"
      >
        {{ row[col.key].title }}
      </small>
      <template v-else>-</template>
    </div>
  </template>

  <!-- Type -->
  <template v-else-if="col.key === 'type'">
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key].title }}
      </span>
    </div>
  </template>

  <!-- Author -->
  <template v-else-if="col.key === 'owner'">
    <div class="igrid cut">
      <small v-if="isAuthor" class="marker primary cut"> Вы </small>
      <span v-else class="cut">
        {{ row[col.key] && row[col.key].fullname ? row[col.key].fullname : '' }}
      </span>
    </div>
  </template>

  <!-- Author -->
  <template v-else-if="col.key === 'for_user'">
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key] && row[col.key].fullname ? row[col.key].fullname : row.for_new_user }}
      </span>
    </div>
  </template>

  <!-- Responsible -->
  <template v-else-if="col.key === 'responsible'">
    <div class="igrid cut">
      <small v-if="isResponsible" class="marker primary cut"> Вы </small>
      <span v-else class="cut">
        {{ row[col.key] && row[col.key].fullname ? cutFullName(row[col.key].fullname) : '-' }}
      </span>
    </div>
  </template>

  <!-- Project -->
  <template v-else-if="col.key === 'company'">
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key] && row[col.key].project ? row[col.key].project : '-' }}
      </span>
    </div>
  </template>

  <!-- Created -->
  <template v-else-if="col.key === 'created_at'">
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key] ? formatRuDateTime(row[col.key]) : '-' }}
      </span>
    </div>
  </template>

  <!-- Other -->
  <template v-else>
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key] ? row[col.key] : '-' }}
      </span>
    </div>
  </template>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, defineProps, toRefs } from 'vue'
import { cutFullName, formatRuDateTime } from '@/plugins'

// Props
const props = defineProps({
  col: {
    type: Object,
    default: () => ({})
  },
  row: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { col, row } = toRefs(props)
const store = useStore()

// Computed
const profile = computed(() => store.getters.profile)
const isAuthor = computed(() => row.value?.author?.id === profile.value.id)
const isResponsible = computed(() => row.value?.responsible?.id === profile.value.id)
</script>

<style></style>
